<template>
		<v-layout class="fill-height" align-center justify-center style="background: rgba(0, 0, 0, 0.4);height: 100%;">
			<v-flex xs12 sm8 md2>
				<material-card light color="indigo" :title="$vuetify.lang.t('$vuetify.common.configlogin')" :text="$vuetify.lang.t('$vuetify.login.input')" class="elevation-12" style="min-width:300px">
					<v-card-text>
						<v-form>
							<v-text-field @keyup.enter="onSubmitForm" v-model="loginFormData.username" :label="$vuetify.lang.t('$vuetify.user.table.name')" id="username" type="text" color="indigo" prepend-icon="mdi-face"></v-text-field>
							<v-text-field @keyup.enter="onSubmitForm" v-model="loginFormData.password" :label="$vuetify.lang.t('$vuetify.user.table.password')" id="password" type="password" color="indigo" prepend-icon="mdi-lock"></v-text-field>
						</v-form>
						<v-card-actions>
							<v-btn block  
                            color="indigo_lighten_1" 
                            style="color:#fff" 
                            v-on:click="onSubmitForm">
                                <span style="color:#fff">{{$vuetify.lang.t('$vuetify.common.login')}}</span>
                            </v-btn>
						</v-card-actions>
					</v-card-text>
				</material-card>
			</v-flex>
		</v-layout>
</template>

<script>
	import * as api from '../../api'
	import { Notification  } from 'element-ui';

	export default {
		data() {
			return {
				dialog: false,
				loginFormData: {
					username: '',
					password: '',
				},
				ConfirmPassword: '',
				reg_username: '',
				reg_password: '',
			}
		},
		mounted(){
			this.$nextTick(()=>{
			})
		},
		methods: {
			onRegisterForm: function(e) {
			},
			onSubmitForm(e) {
				e.preventDefault();

				if(this.loginFormData.username == ''||this.loginFormData.password == ''){
					Notification({
						type: 'error',
						message: this.$vuetify.lang.t('$vuetify.login.nullTip')
					});
				}else{
					const postData = {
						username: this.loginFormData.username,
						password: this.loginFormData.password,
					}
					this.$http.post(api.default.editconfig.loginEditconfig, postData).then(resp => {
						if (!resp)
							return
						var authUser = this.loginFormData.username
						window.sessionStorage.setItem('authUser', authUser);
						window.sessionStorage.setItem('name', resp.data.name);
						window.sessionStorage.setItem('contact', resp.data.contact);
						window.sessionStorage.setItem('type', resp.data.type);
						this.$router.push('/ConfigInfo');
					}).finally(resp => {
						
					})
				}
			},
		}
	}
</script>

<style lang="scss">
	.button-container {
		left: 0;
		opacity: 1;
		visibility: visible;
		transition: .3s ease;
	}
	/* Footer */
	
	.footer {
		margin: 10px 0 0;
		color: #d3d3d3;
		font-size: 18px;
		font-weight: 300;
		text-align: center;
		a {
			color: inherit;
			text-decoration: none;
			transition: .3s ease;
			&:hover {
				color: darken(#d3d3d3, 10%);
			}
		}
		.v-menu__content {
			top: 227px!important;
			left: 464px!important;
		}
	}
</style>
